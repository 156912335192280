import { SingleShow } from "../components/types";
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Video from "../components/video";
import Gallery from "../components/gallery";
import styled from "styled-components";

const Description = styled.div`
  p {
    &:first-child {
      margin-top: 0.3rem;
    }
  }
`;

const Body = styled.div``;

const Post = ({ data }: SingleShow) => {
  const show = data.allWpShow.nodes;
  return (
    <div className="container">
      <Layout>
        {show.map((post) => {
          return (
            <div key={post.id}>
              <div className="header">
                <h1>{post.title}</h1>
                {post.cpt.content ? (
                  <Description
                    dangerouslySetInnerHTML={{ __html: post.cpt.content }}
                  />
                ) : null}
              </div>
              <Body>
                {post.cpt.documentation ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.cpt.documentation,
                      }}
                    />
                  </>
                ) : null}
                {post.cpt.vimeoId ? <Video vimeocaption={post.cpt.vimeocaption? post.cpt.vimeocaption : ''} vimeoID={post.cpt.vimeoId} /> : null}
                {post.cpt.images ? (
                  <Gallery images={post.cpt.images} id={post.id} />
                ) : null}
              </Body>
            </div>
          );
        })}
      </Layout>
    </div>
  );
};
export const Head = ({ data }: SingleShow) => {
  const post = data.allWpShow.nodes[0];
  const description = post.cpt.content;
  const des = description.replace('<p>', '').replace('</p>', '').replace('<br />', '');
  return (
    <>
      <title>{post.title}</title>
      <meta
        name="description"
        content={des ? des : ""}
      />
    </>
  );
};
export default Post;

export const pageQuery = graphql`
  query ($id: Int) {
    allWpShow(filter: { databaseId: { eq: $id } }) {
      nodes {
        id
        title
        slug
        cpt {
          content
          documentation
          vimeoId
          vimeocaption
          images {
            vimeo
            vimeoCaption
            caption
            image {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    sizes: "100vw"
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
